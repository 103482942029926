<template>
  <div>
    <div class="overlay-on-mobile">
      <div>
        <SkidsonLogo width="40px" />
        <p>
          Je kunt deze website alleen op een desktop of laptop bekijken.
        </p>
      </div>
    </div>
    <div class="main-content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import PasswordProtection from './views/LoginView.vue';
import SkidsonLogo from './components/SkidsonLogo.vue';

export default {
  components: {
    PasswordProtection,
    SkidsonLogo
  },
  data() {
    return {
      isAuthenticated: false
    };
  }
};
</script>

<style scoped>
.overlay-on-mobile {
    display: none; 

}

@media (max-width: 900px) {
    .overlay-on-mobile {
        display: block;
        text-align: center;
        padding: 20px;
        min-height: 100vh;
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;  
    }
    .main-content {
        display: none;
    }
}
</style>