<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
                <form @submit.prevent="checkPassword">
                    <SkidsonLogo :width="'40px'"/>
                    <h1 class="display-1">
                        You shall not pass!
                    </h1>
                    <input v-model="password" type="password" class='form-control mb-2' placeholder="Vul code in" />
                    <button type="submit" class="btn btn-dark w-100 btn-lg">Naar demo</button>
                    <p v-if="error">{{ error }}</p>
                </form>
               
            </div>
        </div>
    </div>
  </template>
  
  <script>
    import SkidsonLogo from '@/components/SkidsonLogo.vue';
  export default {
    components: {
      SkidsonLogo
    },
    data() {
      return {
        password: '',
        error: ''
      };
    },
    methods: {
        checkPassword() {
            const correctPassword = 'vvdw'; 
            if (this.password === correctPassword) {
                localStorage.setItem('authenticated', 'true');
                this.$router.push({ name: 'home' });
            } else {
                this.error = 'Incorrecte code. Probeer het opnieuw.';
            }
        }
    }
  };
  </script>

<style scoped>
.container {
    margin-top: 100px;
}

.form-control {
    min-width: 300px;
    background: rgba(255,255,255, 0.1);
    padding: 20px;
    border: none;
    border-radius: 0;
    color: #fff;
}

.form-control::placeholder {
    color: #fff;
}   

.form-control:focus {
    background: rgba(255,255,255, 0.2);
    color: #fff;
}

.btn{
    border-radius: 0;
}

</style>