<template>
    <div>
        <img class='img-fluid'
            :style="{ width: width }"
        src="@/assets/Skidson_Badge_Outlined_Dark_Background.svg" alt="Skidson Badge">
    </div>
</template>

<script>

export default {
    name: 'SkidsonLogo',
    props: {
        width: {
            type: String,
            default: '100px'
        }
    }

};
</script>