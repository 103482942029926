<template>
  <div>
    <div class="container my-5">
      <div class="row">
        <SkidsonLogo :width="'40px'"/>
        <div class="col-md-10 mt-0 mt-md-3 position-relative">
          <div class="gradient-bg">
            <svg xmlns="http://www.w3.org/2000/svg">
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
                  <feBlend in="SourceGraphic" in2="goo" />
                </filter>
              </defs>
            </svg>
            <div class="gradients-container">
              <div class="g1"></div>
              <div class="g2"></div>
              <div class="g3"></div>
              <div class="g4"></div>
              <div class="g5"></div>
              <div class="interactive"></div>
            </div>
          </div>
          <div class="gradient-content">
            <h1 class="rye-regular">Eins, Zwei, Draai</h1>
            <h2>Hardware en Tijdlijn principe</h2>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="container my-5" id="state-control">
      <video autoplay loop muted id="background-video">
        <source src="/vid/oog_mockup.mov" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="row">
        <div class="col-md-12 mb-3">
          <h3>State-control</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <p>Vanuit basis stand naar 1 van de Eins, Zwei, Draai standen</p>
        </div>
        <div class="col-md-3 offset-md-2">
          <p>Vanuit elke Eins, Zwei, Draai stand naar show element (act) stand (variabel)</p>
        </div>
        <div class="col-md-3 offset-md-1">
          <p>Na show element terug naar basisstand</p>
        </div>

      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="act-section" id="s0">
            <h4>Basisstand</h4>
          </div>
        </div>
        <div class="col-md-3 offset-md-2">
          <div class="act-section" id="s1">
            <h4>Eins, Zwei, Draai Stand 1</h4>
          </div>
          <div class="act-section" id="s2">
            <h4>Eins, Zwei, Draai Stand x</h4>
          </div>
          <div class="act-section" id="s3">
            <h4>Eins, Zwei, Draai Stand 6</h4>
          </div>
        </div>
        <div class="col-md-3 offset-md-1">
          <div class="act-section" id="a1">
            <h4>Show stand 1</h4>
          </div>
          <div class="act-section" id="a2">
            <h4>Show stand x</h4>
          </div>
          <div class="act-section" id="a3">
            <h4>Show stand 6</h4>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="container mb-5">
      <div class="row mt-5">
        <div class="col-md-12 mb-3">
          <h3>Technisch overzicht - Hardware</h3>
        </div>
        <div class="col-md-6 topic-section py-4" id="1-1">
          <h6>/1.1</h6>
          <h4>Knop interface voor inschakelen standen door show caller</h4>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 topic-section py-4" id="1-2">
          <h6>/1.2</h6>
          <h4>Hoofdsturing showcontrol voor video en geluid</h4>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 offset-md-2 topic-section py-4" id="1-3">
          <h6>/1.3</h6>
          <h4>Schermen voor klok</h4>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4 offset-md-2 topic-section py-4" id="1-4">
          <h6>/1.4</h6>
          <h4>Mengpaneel </h4>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4 offset-md-2 topic-section py-4" id="1-5">
          <h6>/1.5</h6>
          <h4>Lichtsturing led en conventioneel</h4>
        </div>
        <div class="col-md-4 offset-md-1 topic-section py-4" id="1-5-1">
          <h6>/1.5.1</h6>
          <h4>LED en conventioneel licht</h4>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4 offset-md-2 topic-section py-4" id="1-6">
          <h6>/1.6</h6>
          <h4>Lichtsturing movinghead</h4>
        </div>
        <div class="col-md-4 offset-md-1 topic-section py-4" id="1-6-1">
          <h6>/1.6.1</h6>
          <h4>Licht movinghead</h4>
        </div>
      </div>
    </div>
    <hr>
     <div class="container">
      <div class="row">
        <div class="col-12">
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/1014458389?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="HC concept">
            </iframe>
          </div>
        </div>
      </div>
     </div>
    <hr>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center py-5">
          <p>Aan deze pagina kunnen geen rechten worden ontleend.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { end } from '@popperjs/core';
import LeaderLine from 'vue3-leaderline';
import SkidsonLogo from '@/components/SkidsonLogo.vue';
export default {
  name: 'HomeView',
  components: {
    SkidsonLogo
  },
  methods: {
    addLeaderLine() {
      const line = new LeaderLine(
        document.getElementById('1-1'),
        document.getElementById('1-2'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'bottom',
          endSocket: 'top',
          path: 'fluid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line2 = new LeaderLine(
        document.getElementById('1-2'),
        document.getElementById('1-3'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'left',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
          endLabel:LeaderLine.pathLabel('HDMI', {
            fontFamily: 'Muli',
            fontWeight: 'light',
            fontSize: '1.5rem',
            outlineColor: 'none',
            outlineWidth: 0,
          }),
        }
      );
      const line3 = new LeaderLine(
        document.getElementById('1-2'),
        document.getElementById('1-4'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'left',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
          endLabel:LeaderLine.pathLabel('XLR', {
            fontFamily: 'Muli',
            fontWeight: 'light',
            fontSize: '1.5rem',
            outlineColor: 'none',
            outlineWidth: 0,
          }),
        }
      );
      const line4 = new LeaderLine(
        document.getElementById('1-2'),
        document.getElementById('1-5'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'left',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
          endLabel:LeaderLine.pathLabel('UDP', {
            fontFamily: 'Muli',
            fontWeight: 'light',
            fontSize: '1.5rem',
            outlineColor: 'none',
            outlineWidth: 0,
          }),
        }
      );
      const line5 = new LeaderLine(
        document.getElementById('1-2'),
        document.getElementById('1-6'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'left',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
          endLabel:LeaderLine.pathLabel('UDP', {
            fontFamily: 'Muli',
            fontWeight: 'light',
            fontSize: '1.5rem',
            outlineColor: 'none',
            outlineWidth: 0,
          }),
        }
      );
      const line6 = new LeaderLine(
        document.getElementById('1-5'),
        document.getElementById('1-5-1'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
          endLabel:LeaderLine.pathLabel('DMX', {
            fontFamily: 'Muli',
            fontWeight: 'light',
            fontSize: '1.5rem',
            outlineColor: 'none',
            outlineWidth: 0,
          }),
        }
      );
      const line7 = new LeaderLine(
        document.getElementById('1-6'),
        document.getElementById('1-6-1'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
          endLabel:LeaderLine.pathLabel('DMX', {
            fontFamily: 'Muli',
            fontWeight: 'light',
            fontSize: '1.5rem',
            outlineColor: 'none',
            outlineWidth: 0,
          }),
        }
      );
      const line8 = new LeaderLine(
        document.getElementById('s0'),
        document.getElementById('s1'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line9 = new LeaderLine(
        document.getElementById('s0'),
        document.getElementById('s2'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line10 = new LeaderLine(
        document.getElementById('s0'),
        document.getElementById('s3'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line11 = new LeaderLine(
        document.getElementById('s1'),
        document.getElementById('a1'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line12 = new LeaderLine(
        document.getElementById('s2'),
        document.getElementById('a2'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line13 = new LeaderLine(
        document.getElementById('s3'),
        document.getElementById('a3'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'square',
          endPlug: 'arrow',
        }
      );
      const line14 = new LeaderLine(
        document.getElementById('s3'),
        document.getElementById('a1'),
        { 
          color: '#fff', 
          size: 2,
          startSocket: 'right',
          endSocket: 'left',
          path: 'grid',
          startPlug: 'behind',
          endPlug: 'behind',
        }
      );
    }
  },
  mounted() {
      document.addEventListener('DOMContentLoaded', () => {
      const interBubble = document.querySelector('.interactive');
      let curX = 0;
      let curY = 0;
      let tgX = 0;
      let tgY = 0;

      function move() {
          curX += (tgX - curX) / 20;
          curY += (tgY - curY) / 20;
          interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
          requestAnimationFrame(() => {
              move();
          });
      }

      const actSections = document.querySelectorAll('.act-section');
      const backgroundVideo = document.getElementById('background-video');

      actSections.forEach(section => {
        section.addEventListener('mouseenter', () => {
          backgroundVideo.style.opacity = '1';
        });

        section.addEventListener('mouseleave', () => {
          backgroundVideo.style.opacity = '0.1';
        });
      });

      window.addEventListener('mousemove', (event) => {
          tgX = event.clientX;
          tgY = event.clientY;
      });

      move();
    });
    setTimeout(() => {
      this.addLeaderLine();
    }, 1000);
  }
}

</script>

<style lang="scss" scoped>
#state-control {
  position: relative;
  z-index: 1;
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.05;
  transition: opacity 0.5s ease;
}

.act-section:hover ~ #background-video {
  opacity: 1;
}

.act-section:hover, .act-section.active{
  color: #000;
  --s: 25vmin;
  --p: calc(var(--s) / 2);
  --c1: pink;
  --c2: dodgerblue;
  --c3: white;
  --bg: var(--c3);
  --d: 4000ms;
  --e: cubic-bezier(0.76, 0, 0.24, 1);
  
  background-color: var(--bg);
  background-image:
    linear-gradient(45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(-45deg, var(--c1) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--c2) 75%),
    linear-gradient(-45deg, transparent 75%, var(--c2) 75%);
  background-size: var(--s) var(--s);
  background-position: 
    calc(var(--p) *  1) calc(var(--p) *  0), 
    calc(var(--p) * -1) calc(var(--p) *  1), 
    calc(var(--p) *  1) calc(var(--p) * -1), 
    calc(var(--p) * -1) calc(var(--p) *  0);
  animation: 
    color var(--d) var(--e) infinite,
    position var(--d) var(--e) infinite;
}

@keyframes color {
  0%, 25% {
    --bg: var(--c3);
  }
  26%, 50% {
    --bg: var(--c1);
  }
  51%, 75% {
    --bg: var(--c3);
  }
  76%, 100% {
    --bg: var(--c2);
  }
}

@keyframes position {
  0% {
    background-position: 
      calc(var(--p) *  1) calc(var(--p) *  0), 
      calc(var(--p) * -1) calc(var(--p) *  1), 
      calc(var(--p) *  1) calc(var(--p) * -1), 
      calc(var(--p) * -1) calc(var(--p) *  0);
  }
  25% {
    background-position: 
      calc(var(--p) *  1) calc(var(--p) *  4), 
      calc(var(--p) * -1) calc(var(--p) *  5), 
      calc(var(--p) *  1) calc(var(--p) *  3), 
      calc(var(--p) * -1) calc(var(--p) *  4);
  }
  50% {
    background-position: 
      calc(var(--p) *  3) calc(var(--p) * 8), 
      calc(var(--p) * -3) calc(var(--p) * 9), 
      calc(var(--p) *  2) calc(var(--p) * 7), 
      calc(var(--p) * -2) calc(var(--p) * 8);
  }
  75% {
    background-position: 
      calc(var(--p) *  3) calc(var(--p) * 12), 
      calc(var(--p) * -3) calc(var(--p) * 13), 
      calc(var(--p) *  2) calc(var(--p) * 11), 
      calc(var(--p) * -2) calc(var(--p) * 12);
  }
  100% {    
    background-position: 
      calc(var(--p) *  5) calc(var(--p) * 16), 
      calc(var(--p) * -5) calc(var(--p) * 17), 
      calc(var(--p) *  5) calc(var(--p) * 15), 
      calc(var(--p) * -5) calc(var(--p) * 16);
  }
}

@media (prefers-reduced-motion) {
  body {
    animation: none;
  }
}

h1{
  font-size: 5rem;
  margin-bottom: 1rem;
}
h4{
  font-weight: 400;
}
@keyframes movEinsCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.gradient-content{
  z-index: 2;
  position: relative;
  color: #fff;
  padding: 30px;;
}

.gradient-bg {
  border-radius: 30px 0 30px 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  svg {
    display: none;
  }


  .gradients-container {
    filter: url(#goo) blur(40px) ;
    width: 100%;
    height: 100%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: center center;
    animation: moveVertical 30s ease infinite;

    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: movEinsCircle 20s reverse infinite;

    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: movEinsCircle 40s linear infinite;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;

    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: movEinsCircle 20s ease infinite;

    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}
</style>
